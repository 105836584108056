<script>
import Tabulation from "@/view/tabulate/Tabulate.vue";
import SearchCombination from "@/view/search/SearchCombination.vue";
import {download} from "@/utils/downLoad";
export default {
  components: {SearchCombination, Tabulation},
  data(){
    return{
      tableData: null,
      pageSize: 20,
      pageNum: 1,
      total: 0,
      search: ""
    }
  },
  created() {
    this.getTableList({
      id: this.$route.query.id
    })
  },
  methods: {
    /**
     * 获取订单列表
     */
    getTableList(data = null, mod = null, pageNum = null){
      this.axios.get(this.$api.publicUrl.OrderList, {
        params: {
          pageSize: this.pageSize,
          pageNum: pageNum || this.pageNum,
          ...data
        }
      }).then(res =>{
        if(res.data.status === 200){

          this.tableData = res.data.data.list
          this.total = res.data.data.total
          if(mod){
            this.$message.success("查询成功！")
          }
        }else {
          this.$message(res.data.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "昵称", type: "text", key: "nickname", prop: "nickname"}, {label: "姓名", type: "text", key: "userName", prop: "userName"}],
          [{label: "电话", type: "number", key: "phoneNumber", prop: "phoneNumber"}, {label: "来源", type: "select", key: "userSource", prop: "userSource",
            options: [
              {value: 'HTML', label: 'HTML'},
              {value: 'CSS', label: 'CSS'},
              {value: 'JavaScript', label: 'JavaScript'}
            ]}
          ],
          [{label: "拉黑原因", type: "textarea", key: "remark", prop: "remark"}]
        ],
        formData: data || {
          nickname: "",
          userName: "",
          phoneNumber: "",
          userSource: "",
          remark: "",
        },
        rules: {
          userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }],
          remark: [{ required: true, message: '请输入拉黑原因', trigger: 'blur'}],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 新增
     */
    addUserInfo(){
      this.createTemplateData("新增黑名单用户", "add")
    },
    /**
     * 查看
     */
    getOrderInfo(data){
      this.$createPopup("queryOrder",{
        ti: "查看详情",
        id: data.id,
        basicInfo: data
      }, "query", this.poPupCallback, this.dataCallBack)
    },
    /**
     * 删除
     */
    delUserInfo(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendService(id, "del")
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    /**
     * 弹出层回调事件
     * 这里可能需要针对某些接口做数据预处理， 最后将数据发送给服务器
     * @param data
     * @param mode
     */
    poPupCallback(data, mode){
      this.sendService(data, mode)
    },
    /**
     * 向服务器发送请求，以及回调的状态处理
     * @param data
     * @param mode
     */
    sendService(data, mode){
      new Promise((resolve, reject)=>{
        switch (mode){
          case "add":
            this.axios.post(this.$api.publicUrl.OrderList, data).then(res =>{
              if(res.data.status === 200){
                resolve("添加成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "update":
            this.axios.put(this.$api.publicUrl.OrderList, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "del":
            this.axios.delete(this.$api.publicUrl.OrderList + "/" + data ).then(res =>{
              if(res.data.status === 200){
                resolve("删除成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
        }
      }).then(res =>{
        this.getTableList()
        this.$message({
          message: res,
          type: 'success'
        })
      }).catch(err =>{
        this.$message.error(err);
      })
    },

    // /**
    //  * 导出
    //  */
    // orderExport(){
    //   this.$createPopup("form",{
    //     ti: "订单导出",
    //     tree: [
    //       [{label: "日期范围", type: "daterange", key: "dateRange", prop: "dateRange", format: null, valueFormat: "yyyy-MM-dd",
    //         pickerOptions: {
    //           onPick: ({ maxDate, minDate }) => {
    //             this.choiceDate = minDate.getTime()
    //             if (maxDate) this.choiceDate = ''
    //           },
    //           disabledDate: time => {
    //             if (this.choiceDate) {
    //               const one = 15 * 24 * 3600 * 1000
    //               const minTime = this.choiceDate - one
    //               const maxTime = this.choiceDate + one
    //               return (
    //                 time.getTime() < minTime ||
    //                 time.getTime() > maxTime ||
    //                 time.getTime() > Date.now()
    //               )
    //             } else {
    //               return time.getTime() > Date.now()
    //             }
    //           }
    //         }}],
    //       [{label: "车辆来源", type: "select"}, {label: "订单状态", type: "select"}]
    //     ],
    //     formData: {
    //       dateRange: "",
    //     },
    //     rules: {
    //       dateRange: [{ required: true, message: '请选择日期区间', trigger: 'change'}],
    //     }
    //   }, null, this.poPupCallbackDownload)
    // },
    // /**
    //  * 弹出层下载回调方法
    //  * @param data
    //  */
    // poPupCallbackDownload(data){
    //   this.requestDownload({
    //     startTime: data.dateRange[0],
    //     stopTime: data.dateRange[1]
    //   }, data.dateRange)
    // },

    orderExport(){
      this.$confirm('导出将按照上方的查询条件筛选，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '正在导出!'
        });
        this.requestDownload(this.$refs.searchRef.conditions)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消导出'
        });
      })
    },
    /**
     * 向服务器发送下载请求
     * @param data
     * @param ti
     */
    requestDownload(data, ti){
      download(data, `${ti}订单数据`, "post", this.$api.publicUrl.OrderExport)
    },

    /**
     * 搜索组件的回调
     * @param data {Object} 搜索的条件数据
     */
    searchCallBack(data){
      this.getTableList(data, true, 1)
    },
    /**
     * 弹出层数据回调事件
     * 作用：弹出层基本数据的更新会同步到此页面
     */
    dataCallBack(data){
      for (let i = 0; i < this.tableData.length; i++){
        if(this.tableData[i].id === data.id){
          this.$set(this.tableData, i, data)
          break
        }
      }
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getTableList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getTableList()
    }
  }
}
</script>
<template>
  <fragment v-if="tableData">
    <h1 class="title">请输入查询条件</h1>
    <section class="search">
      <SearchCombination :data="{
        list: [
          [{ti: '司机姓名', type: 'input', key: 'operatorName', class: 'min'},
          {ti: '客户姓名', type: 'input', key: 'userName', class: 'min'}, {ti: '车辆来源', type: 'select', key: 'orderSource', options: [
            {value: '哈啰', label: '哈啰'},
            {value: '携程', label: '携程'},
            {value: '飞猪', label: '飞猪'},
            {value: '租租车', label: '租租车'},
            {value: '同行', label: '同行'},
            {value: '线下', label: '线下'},
          ]}],
          [{ti: '车辆信息', type: 'input', key: 'carInfo', class: 'min'}, {ti: '订单状态', type: 'select', key: 'orderStatus', options:[
            {value: 3, label: '待取车'},
            {value: 4, label: '用车中'},
            {value: 5, label: '已还车'},
            {value: 6, label: '已超时'},
            {value: -1, label: '已作废'},
          ]}],
          [{ti: '取车日期', type: 'datePicker', key: ['takeTimeStart', 'takeTimeEnd']},
          {ti: '预计还车日期', type: 'datePicker', key: ['returnTimeStart', 'returnTimeEnd'], labelWidth: 100}],
          [
            {ti: '实际还车日期', type: 'datePicker', key: ['actualReturnTimeStart', 'actualReturnTimeEnd'], labelWidth: 100},
            {ti: '订单ID', type: 'input', key: 'id', class: 'min'}
          ]
        ],
        senior: true
      }" @searchCallBack="searchCallBack" ref="searchRef"></SearchCombination>
    </section>
    <section class="control">
      <div class="con_ti">订单总数 <span>{{this.total}}</span> 单</div>
      <div class="btn">
        <el-button type="primary" @click="orderExport" size="small">导出</el-button>
      </div>
    </section>
    <section class="tabulation" v-if="tableData">
      <Tabulation :data="{
        columnName: [
          {name: '客户姓名', prop: 'userName', type: 'text', needSort: false},
          {name: '租期', prop: ['takeCarTimeEstimate', 'returnCarTimeEstimate'], type: 'textsPlus', needSort: false, style: 'flex: 2; min-width: 340px'},
          {name: '预定车辆', prop: ['reserveCarBrand', 'reserveCarSeries'], type: 'texts', needSort: false},
          {name: '实际车辆', prop: 'carInfo', type: 'text', needSort: false, style: 'flex: 1.5'},
          {name: '订单来源', prop: 'orderSource', type: 'text', needSort: false, style: 'min-width: 80px'},
          {name: '司机', prop: 'operatorName', type: 'text', needSort: false},
          {name: '保险', prop: 'orderInsurance', type: 'text', needSort: false},
          {name: '订单状态', prop: 'orderStatus', type: 'text', format: 'orderStatus', specialAttrs: true, needSort: false},
          {name: '更新时间', prop: 'updateTime', type: 'text', needSort: false, style: 'min-width: 180px'},
          {name: '创建时间', prop: 'createTime', type: 'text', needSort: false, style: 'min-width: 180px'}
        ],
        rows: this.tableData,
        control: [
          {name: '查看详情', type: 'warning', method: this.getOrderInfo},
          {name: '删除', type: 'danger', method: this.delUserInfo}
        ]
      }"></Tabulation>
    </section>
    <section class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="this.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total">
      </el-pagination>
    </section>
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/></fragment>
</template>
<style src="@/assets/css/componentStyle.css" scoped></style>


