var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableData)?_c('fragment',[_c('h1',{staticClass:"title"},[_vm._v("请输入查询条件")]),_c('section',{staticClass:"search"},[_c('SearchCombination',{ref:"searchRef",attrs:{"data":{
      list: [
        [{ti: '司机姓名', type: 'input', key: 'operatorName', class: 'min'},
        {ti: '客户姓名', type: 'input', key: 'userName', class: 'min'}, {ti: '车辆来源', type: 'select', key: 'orderSource', options: [
          {value: '哈啰', label: '哈啰'},
          {value: '携程', label: '携程'},
          {value: '飞猪', label: '飞猪'},
          {value: '租租车', label: '租租车'},
          {value: '同行', label: '同行'},
          {value: '线下', label: '线下'},
        ]}],
        [{ti: '车辆信息', type: 'input', key: 'carInfo', class: 'min'}, {ti: '订单状态', type: 'select', key: 'orderStatus', options:[
          {value: 3, label: '待取车'},
          {value: 4, label: '用车中'},
          {value: 5, label: '已还车'},
          {value: 6, label: '已超时'},
          {value: -1, label: '已作废'},
        ]}],
        [{ti: '取车日期', type: 'datePicker', key: ['takeTimeStart', 'takeTimeEnd']},
        {ti: '预计还车日期', type: 'datePicker', key: ['returnTimeStart', 'returnTimeEnd'], labelWidth: 100}],
        [
          {ti: '实际还车日期', type: 'datePicker', key: ['actualReturnTimeStart', 'actualReturnTimeEnd'], labelWidth: 100},
          {ti: '订单ID', type: 'input', key: 'id', class: 'min'}
        ]
      ],
      senior: true
    }},on:{"searchCallBack":_vm.searchCallBack}})],1),_c('section',{staticClass:"control"},[_c('div',{staticClass:"con_ti"},[_vm._v("订单总数 "),_c('span',[_vm._v(_vm._s(this.total))]),_vm._v(" 单")]),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.orderExport}},[_vm._v("导出")])],1)]),(_vm.tableData)?_c('section',{staticClass:"tabulation"},[_c('Tabulation',{attrs:{"data":{
      columnName: [
        {name: '客户姓名', prop: 'userName', type: 'text', needSort: false},
        {name: '租期', prop: ['takeCarTimeEstimate', 'returnCarTimeEstimate'], type: 'textsPlus', needSort: false, style: 'flex: 2; min-width: 340px'},
        {name: '预定车辆', prop: ['reserveCarBrand', 'reserveCarSeries'], type: 'texts', needSort: false},
        {name: '实际车辆', prop: 'carInfo', type: 'text', needSort: false, style: 'flex: 1.5'},
        {name: '订单来源', prop: 'orderSource', type: 'text', needSort: false, style: 'min-width: 80px'},
        {name: '司机', prop: 'operatorName', type: 'text', needSort: false},
        {name: '保险', prop: 'orderInsurance', type: 'text', needSort: false},
        {name: '订单状态', prop: 'orderStatus', type: 'text', format: 'orderStatus', specialAttrs: true, needSort: false},
        {name: '更新时间', prop: 'updateTime', type: 'text', needSort: false, style: 'min-width: 180px'},
        {name: '创建时间', prop: 'createTime', type: 'text', needSort: false, style: 'min-width: 180px'}
      ],
      rows: this.tableData,
      control: [
        {name: '查看详情', type: 'warning', method: this.getOrderInfo},
        {name: '删除', type: 'danger', method: this.delUserInfo}
      ]
    }}})],1):_vm._e(),_c('section',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","current-page":this.pageNum,"page-sizes":[10, 20, 50, 100],"page-size":this.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":this.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]):_c('fragment',[_c('img',{staticClass:"loading",attrs:{"src":require("../../../assets/images/loading.gif"),"height":"100","width":"100","alt":"加载中"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }